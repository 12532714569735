.buttons {
    margin-top: 20px;
  }
  
  .button {
    display: block;
    width: 200px;
    padding: 10px;
    margin: 10px auto;
    font-size: 16px;
    color: white;
    background-color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #555;
  }
.jugadores-table {
    font-family: 'Karantina', sans-serif;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #dddddd;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .jugadores-table th, .jugadores-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .jugadores-table th {
    background-color: #F5F5F5;
    text-align: left;
    border-bottom: 1.5px solid #ddd; 
    font-size: 18px;
  }
  
  .jugadores-table tr:nth-child(even) {
    background-color: #F5F5F5;
    font-size: 16px;
  }
  
  .jugadores-table tr:hover {
    background-color: #ddd;
  }
  
  .ver-ficha-btn {
    background-color: transparent;
    border: none;
    color: rgb(0, 0, 0);
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .ver-ficha-btn:hover {
    color: #ED6D10;
    background: transparent;
    text-decoration: none;
  }
  
  .jugadores-table thead th:first-child {
    border-top-left-radius: 8px;
  }
  
  .jugadores-table thead th:last-child {
    border-top-right-radius: 8px;
  }
  
  .jugadores-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  
  .jugadores-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
  
  .back-btn {
    margin-bottom: 10px;
    background-color: #555;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .back-btn:hover {
    background-color: #333;
  }
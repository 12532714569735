.AdminPrincipal {
  text-align: left;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
}

.menu {
  display: flex;
  margin-top: 0;
  padding: 0;
}

.menu-item {
  position: relative;
  text-align: left;
  margin: 0.5px;
}

.menu-item h3 {
  font-family: 'Karantina', sans-serif;
  font-size: 1.2em;
  margin: 0.5px;
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 120px;
}

.menu-item .dropdown-icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.menu-item ul {
  list-style-type: none;
  padding: 0;
  margin: 0.5px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.menu-item:hover ul {
  display: block;
}

.menu-item ul li {
  margin: 0.5px;
}

.menu-item ul li button {
  width: 100%;
  font-size: 1em;
  padding: 10px;
  background-color: #ED6D10;
  color: #fff;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  text-align: center;
}

.menu-item ul li button:hover {
  background-color: #f7987c;
}

.logout {
  margin-left: auto; 
  margin-top: 2.5px;
}

.logout button {
  font-size: 1em;
  padding: 10px 20px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.logout button:hover {
  background-color: #555;
}
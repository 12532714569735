.RepInscribir {
  text-align: center;
}

.RepInscribir-main {
  margin-top: 0px;
}

h2 {
  font-family: 'Karantina', sans-serif;
  font-size: 2em;
  margin-bottom: 20px;
}

.inscribir-form {
  display: grid;
  align-items: center;
  text-align: left;
  flex-direction: column;
  justify-content: center;
}

.photo-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.photo-upload button {
  background-color: #d3d3d3;
  border: 2px solid #000; 
  border-radius: 50%;
  padding: 50px;
  cursor: pointer;
}


.section {
  margin-bottom: 20px;
}

.section h3 {
  margin-bottom: 10px;
  font-family: 'Karantina', sans-serif;
}

.rep-form-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tres columnas dentro del grupo */
  gap: 10px; /* Espacio entre los elementos de la cuadrícula */
  justify-items: start; /* Alinea los elementos al inicio de cada celda */
  margin-bottom: 10px;
}

.form-item {
  display: flex;
  flex-direction: row;
  align-items: center; /* Centra verticalmente los elementos dentro de .form-item */
  justify-content: space-between; /* Espacia los elementos entre sí */
  padding: 10px; /* Añade padding para un mejor espaciado */
}

.form-item label {
  text-align: left; /* Alinea el texto del label a la derecha */
  margin: 0; /* Sin margen adicional */
  flex: 1; /* Permite que el label tome espacio disponible */
  padding-right: 10px; /* Añade un poco de espacio entre el label y el input */
}

.form-item input {
  margin: 0; /* Sin margen adicional */
  box-sizing: border-box; /* Incluye padding en el ancho total */
  flex: 2; /* Permite que el input tome más espacio que el label */
  min-width: 0; /* Evita que el input se desborde */
}

.rep-form-group-doc {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px; /* Reduce el espacio entre los elementos */
  margin-bottom: 10px;
}

.form-item-doc {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Espacia los elementos entre sí */
  align-items: center; /* Centra los elementos horizontalmente */
  margin-bottom: 10px; /* Espacio entre cada form-item */
}

.form-item-doc label-doc {
  text-align: left; /* Alinea el texto del label al centro */
  margin-bottom: 5px; /* Espacio entre el label y el input */
  margin: 0; /* Sin margen adicional */
  flex: 1; /* Permite que el label tome espacio disponible */

}

.form-item-doc input {
  margin: 0;
  box-sizing: border-box;
  min-width: 0; /* Evita que el input se desborde */
  flex: 2; /* Permite que el input tome más espacio que el label */
}

label {
  font-family: 'Karantina', sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  font-size: 1.05em;
  border-radius: 5px;
  box-sizing: border-box;
  max-width: 100px;
  max-height: 5%;
}

input{
  width: 200px;
}

label-doc {
  max-width: 100px;
}

input[type="file"] {
  max-width: 309px; 
  width: 100%;
  height: 45px;
  padding: 10px;
  font-size: 0.8em;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin-left: 20px;
}

/* Asegúrate de que los select tengan el mismo estilo que los inputs */
select {
  width: 200px; /* Ajusta el ancho según el diseño */
  padding: 10px; /* Ajusta el padding según el diseño */
  font-size: 0.8em; /* Ajusta el tamaño de la fuente según el diseño */
  border: 1px solid #ccc; /* Mismo borde que los inputs */
  border-radius: 5px; /* Mismo radio de borde que los inputs */
  box-sizing: border-box; /* Incluye padding en el ancho total */
  margin: 0; /* Sin margen adicional */
  text-align: center;
}

select:disabled {
  background-color: #f5f5f5; /* Color de fondo para los selects deshabilitados */
  cursor: not-allowed; /* Cursor para elementos deshabilitados */
}
.documentos-table {
    font-family: 'Karantina', sans-serif;
    width: 100%;
    border-collapse:collapse;
    border: 1px solid #dddddd;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .documentos-table th, .documentos-table td {
    border: 1px solid #ddd;
    padding: 8px;
    
  }
  
  .documentos-table th {
    background-color: #F5F5F5;
    text-align: left;
    border-bottom: 1.5px solid #ddd; 
    font-size: 18px;
}

  
  .documentos-table tr:nth-child(odd) {
    background-color: #F5F5F5;
    font-size: 16px;

  }
  
  .documentos-table tr:hover {
    background-color: #ddd;
  }
  
  .ver-documento-btn {
    background-color: transparent;
    border: none;
    color: rgb(0, 0, 0);
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .ver-documento-btn:hover {
    color: #ED6D10;
    background: transparent;
    text-decoration: none;
  }
  
  .documentos-table thead th:first-child {
    border-top-left-radius: 8px;
  }
  
  .documentos-table thead th:last-child {
    border-top-right-radius: 8px;
  }
  
  .documentos-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  
  .documentos-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
  
.title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .club-title {
    font-size: 1.2em; 
    background-color: #ED6D10;
    color: #fff;
    padding: 10px;
    border-radius: 1px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 120px;
    margin: 1px;
  }
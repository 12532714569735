/* src/pages/Inicio.css */
.Inicio {
    text-align: center;
  }
  
  .Inicio-main {
    margin-top: 50px;
  }
  
  .user-icon img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
.App-header {
    height: 100%;
    width: 100%;
  }
  
  .top-section {
    background-color: white;
    height: 40%;
    display: flex;
    justify-content: flex-start; 
    align-items: center;
  }
  
  .bottom-section {
    background-color: #000; 
    height: 60%; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    color: white;
  }
  
  .logos {
    height: 100%;
    display: flex;
    justify-content: flex-start; 
    width: 20%;
  }
  
  .feb-logo {
    height: 75px;
    
  }
  .fiba-logo {
    height: 75px;
  }
  
  h1 {
    font-family: 'Karantina', sans-serif;
    font-size: 2.3em;
    text-align: center;
    flex-grow: 1;
  }
  
  .ecuador-logo {
    height: 75px;
  }
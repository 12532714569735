.clubes-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
  
  .back-button {
    background: none;
    background-color: none;
    border: none;
    color: #000;
    font-size: 1.5em;
    cursor: pointer;
    display: flex;
    margin-left: 35px;
    margin-top: 5px; 
  }
  .back-button:hover,
  .back-button:focus,
  .back-button:active {
    box-shadow: none;
    outline: none; 
    background: none; 
  }
  
  .centered-title {
    font-size: 1.5em;
    text-align: center;
  }